var main = require('integrations/main');

main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.modalDrawer = require('./components/modalDrawer');
main.baseFiles.readMore = require('./components/readMore');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.back = require('./utilities/back');
main.baseFiles.search = require('./components/search');
main.baseFiles.giftCertificate = require('./giftCertificate/giftCertificate').init;
main.baseFiles.cart = require('./cart');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.base = require('./product/base');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.modal = require('./components/modal').init;
main.baseFiles.swatchVeilTrigger = require('./product/swatchVeilTrigger');
main.baseFiles.scrollTo = require('./utilities/scrollTo');
main.baseFiles.confirmation = require('./checkout/confirmation');
main.baseFiles.login = require('./login/login');

module.exports = main;
